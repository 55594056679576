<template>
  <div>
    <NavigationSubNav main-route="data" :title="actionName" />
    <div class="container-fluid pb-1">
      <Loader v-if="showLoader" :progress="progress" />

      <div v-else class="mb-sm-4 mx-3 my-4 row" style="top: 75px">
        <div class="col-lg-12">
          <div class="border">
            <div class="row p-4">
              <div class="col">
                <h5 class="h5 text-muted font-weight-bold">{{ title }}</h5>
                <p>{{ message }}</p>
              </div>
              <div>
                <b-dropdown
                  id="dropdown-1"
                  text="Share this Page"
                  class="float-right"
                  variant="primary"
                  :disabled="editting.length == 0"
                  block
                >
                  <b-dropdown-item>Share via Link</b-dropdown-item>
                  <b-dropdown-item>Share to user </b-dropdown-item>
                </b-dropdown>
                <button :disabled="editting.length == 0" class="btn mr-2 float-right btn-warning">
                  Advance Filters
                </button>
              </div>
            </div>

            <div class="row pt-3 px-3 py-3">
              <div class="col-lg-2 mb-1">
                <span v-if="edittingEnabled">{{ tableDescription }}</span>
              </div>

              <div v-for="entry in filters" :key="entry.name" class="float-right col">
                <el-select
                  v-model="tableFilters[entry.name]"
                  clearable
                  filterable
                  :placeholder="entry.label"
                  class="mr-2 row"
                >
                  <el-option
                    v-for="option in entry.options"
                    :key="option"
                    :value="option"
                    :label="option"
                  >
                  </el-option>
                </el-select>
              </div>

              <div class="col-lg-2 mb-1">
                <button
                  :disabled="editting.length == 0"
                  class="btn option-btn btn-block btn-warning"
                  @click.prevent="handleDeleteSelectedCells"
                >
                  Delete <b-icon icon="trash-fill" aria-hidden="true"></b-icon>
                  <small v-if="selectedRows.length > 0" class="tab__badge text-white">{{
                    selectedRows.length
                  }}</small>
                </button>
              </div>
            </div>

            <TableComponent
              :fields="fields"
              bordered
              :data="preview"
              :loading="tableLoading"
              :empty-text="emptyText"
              @sizeChange="handlePageSizeChange"
              @selected="handleSelection"
            >
              <template #cell="{ row }">
                <b-form-input
                  type="text"
                  :value="row.value"
                  @change="(e) => handleChange(e, row)"
                />
              </template>
            </TableComponent>

            <div class="row mb-3 p-4 pt-3 px-3 py-3">
              <div class="col-lg-12">
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-danger shadow"
                  @click.prevent="cancel"
                >
                  Cancel
                </button>
                <button
                  :disabled="editting.length == 0"
                  class="btn mr-2 action-btn btn-primary shadow"
                  @click="save"
                >
                  Save
                </button>
                <button
                  :disabled="editting.length == 0"
                  style="width: fit-content"
                  class="btn mr-2 action-btn float-right btn-success shadow"
                  @click="beforeProceed"
                >
                  Normalize
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- shows a prompt , properties are defined in Mixin Object -->
    <ProgressPrompt
      :width="promptWidth"
      :visible="isProgresPromptVisible"
      :cancel="promptCancel"
      :confirm="promptConfirm"
      :message="promptMessage"
      :title="promptTitle"
      :cancel-text="promptCancelText"
      :cancel-type="promptCancelClass"
      :confirm-text="promptConfirmText"
      :confirm-type="promptConfirmClass"
      @close="onPromptClose"
    />
  </div>
</template>

<script>
/**
 * @typedef {import("../../store/modules/data.old").Cell} Cell
 */

import NavigationSubNav from "../../components/NavigationSubNav.vue";
import Loader from "../../components/Loader.vue";
import TableComponent from "../../components/Table.vue";
import DataMixin from "./mixin";
import UploadResource from "../../api/fileResource";
import { META_DATA_CHECK_STAGE } from "../../store/stages";
import XLSX from "xlsx";
import { mapGetters } from "vuex";
import { random } from "../../utils";

const uploadResource = new UploadResource();

export default {
  name: "PreviewEditData",
  components: {
    NavigationSubNav,
    Loader,
    TableComponent,
  },
  mixins: [DataMixin],
  data() {
    return {
      hasChanges: false,
      promptWidth: "34%",
    };
  },
  computed: {
    /**
     * Get data state from store
     */
    ...mapGetters({
      originalFile: "data/file",
    }),

    title() {
      return "Preview/Edit Data";
    },
    message() {
      if (!this.filteredData.length) return "";
      return "Your data has been checked for consistency. It's good to go.";
    },
    /**
     *
     * @see DataMixin.computed
     * @see Cell
     * @returns {Array<Cell>}
     */
    preview() {
      return this.editting.map((x) => x);
    },
    headers() {
      if (!this.preview.length) return [];
      return Object.keys(this.preview[0]).filter((x) => !["$id", "__EMPTY"].includes(x));
    },
  },
  methods: {
    /**
     * confirm proceed
     */
    beforeProceed() {
      if (!this.hasChanges) return this.proceed();
      this.showProgressPrompt("Save File", "Are you done editting?", {
        cancel: "No",
        confirm: "Yes",
        confirmClass: "success",
        async confirmCallback() {
          this.proceed();
        },
      });
    },
    /**
     *Navigates to  next index on the  Next index Navigation Bar
     @see {NavigationSubNav}
     */
    async proceed() {
      const file = this.createExcelFile();
      if (!file) return;

      const formData = new FormData();
      formData.append("file", file);

      await this.setRequestProgress(random(35, 55));
      this.$progress.start();
      this.isProcessing = true;

      uploadResource
        .create(formData)
        .then(async (response) => {
          await this.$store.dispatch("data/setData", response);
          this.$store.commit("data/SET_STAGE", META_DATA_CHECK_STAGE);
          await this.setRequestProgress(100);

          this.$router
            .push("/data/check-meta-data")
            .then(() => {})
            .catch((e) => console.error(e));
        })
        .catch(async (error) => {
          console.error(error);
          await this.setRequestProgress(random(70, 90));
        })
        .finally(() => {
          this.$progress.done();
          this.isProcessing = false;
        });
    },
    /**
     *@see {DataMixin.methods}
     */
    save() {
      /**
       * @see DataMixin.methods.showProgressPrompt
       */
      this.showProgressPrompt(
        "Save progress",
        "Are you sure you want to save and continue later?",
        {
          cancel: "Continue",
          confirm: "Save and continue later",
          confirmClass: "primary",
          async confirmCallback() {
            this.$store.dispatch("data/storeData");
          },
        }
      );
    },

    /**
     * @param {Event} event
     * @param {Object} row
     */
    handleChange(value, row) {
      let cell = {};
      let _value = parseFloat(value, 10);
      if (!isNaN(_value)) cell[row.field.key] = _value;
      else cell[row.field.key] = value;

      cell.$id = row.item.$id;
      this.$store.dispatch("data/updateEditingCell", cell);
      this.hasChanges = true;
    },

    /**
     * @returns {(File|null)} file
     */
    createExcelFile() {
      /**
       * @type {Array<Cell>}
       */
      let data = JSON.parse(JSON.stringify(this.editting));
      if (!data.length) return null;

      data = data.map((cell) => {
        delete cell.$id;
        // eslint-disable-next-line prettier/prettier
        delete cell["__EMPTY"];
        return cell;
      });

      const headers = Object.keys(data[0]);
      const entries = data.map((cell) => Object.values(cell));

      entries.unshift(headers);
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(entries);

      XLSX.utils.book_append_sheet(wb, ws, "sheet 1");

      const type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;";
      const blob = new Blob([XLSX.write(wb, { type: "array", bookType: "xlsx" })], {
        type,
      });
      const file = new File([blob], this.originalFile.name, {
        type,
        lastModified: new Date().getTime(),
      });

      return file;
    },
  },
};
</script>

<style lang="scss" scoped></style>
